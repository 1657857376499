var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "orgProductForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Medicine",
      "prop": "Medicine"
    }
  }, [_c('el-autocomplete', {
    attrs: {
      "clearable": "",
      "fetch-suggestions": _vm.querySearchAsync,
      "placeholder": "Please type"
    },
    on: {
      "select": _vm.handleSelect
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "name"
        }, [_vm._v(_vm._s(item.name))]), _c('small', {
          staticClass: "uuid"
        }, [_vm._v(_vm._s(item.uuid))])];
      }
    }]),
    model: {
      value: _vm.productInput.name,
      callback: function ($$v) {
        _vm.$set(_vm.productInput, "name", $$v);
      },
      expression: "productInput.name"
    }
  }, [_c('i', {
    staticClass: "el-icon-edit el-input__icon",
    attrs: {
      "slot": "suffix"
    },
    slot: "suffix"
  })]), _c('div', {
    directives: [{
      name: "orgType",
      rawName: "v-orgType",
      value: ['buyer'],
      expression: "['buyer']"
    }]
  }, [_c('el-switch', {
    on: {
      "change": function ($event) {
        return _vm.toggleCombined();
      }
    },
    model: {
      value: _vm.filterCombination,
      callback: function ($$v) {
        _vm.filterCombination = $$v;
      },
      expression: "filterCombination"
    }
  }), _vm._v(" Exclude combination items ")], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Alias",
      "prop": "product_name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Product Name Alias",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.product_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "product_name", $$v);
      },
      expression: "form.product_name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Internal Product ID",
      "prop": "org_internal_product_id"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Internal Product ID",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.org_internal_product_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "org_internal_product_id", $$v);
      },
      expression: "form.org_internal_product_id"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "iPharm ID",
      "prop": "org_ipharm_id"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "iPharm ID",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.org_ipharm_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "org_ipharm_id", $$v);
      },
      expression: "form.org_ipharm_id"
    }
  })], 1), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }