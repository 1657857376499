var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "id": "modal-orgproduct-editor",
      "title": "Organisation Product Details",
      "size": "lg",
      "hide-footer": true
    },
    on: {
      "hidden": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('OrgProductEditorForm', {
    ref: "orgProductEditorForm"
  }), [!_vm.editable ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.enableEdit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil-lock"
    }
  }), _vm._v(" Edit ")], 1) : _c('div', {
    staticClass: "float-right"
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "size": "sm",
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "content-save",
      "size": 18
    }
  }), _vm._v(" Save ")], 1), _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "size": "sm",
      "variant": "outline-info"
    },
    on: {
      "click": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "refresh",
      "size": 18
    }
  }), _vm._v(" Reset ")], 1), !_vm.creating ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "size": "sm",
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "cancel",
      "size": 18
    }
  }), _vm._v(" Cancel ")], 1) : _vm._e(), _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.hide();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "close-box",
      "size": 18
    }
  }), _vm._v(" Close ")], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }